import * as React from 'react';


export const storePagesConfig = [
  {
    path: '/store/pos',
    component: React.lazy(() => import('./Pages/PosPage')),
  },
  {
    path: '/store/customers',
    component: React.lazy(() => import('../../components/customer/Customers')),
  }
];
